/* ----------------------------------------------------------------------------------------- */
/* --- FUENTES --------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@font-face {
  font-family: MuseoSansCondensed-300;
  src: url(./recursos/fuentes/MuseoSansCondensed-300.ttf);
}
@font-face {
  font-family: MuseoSansCondensed-500;
  src: url(./recursos/fuentes/MuseoSansCondensed-500.ttf);
}
@font-face {
  font-family: MuseoSansCondensed-700;
  src: url(./recursos/fuentes/MuseoSansCondensed-700.ttf);
}
@font-face {
  font-family: MuseoSansCondensed-900;
  src: url(./recursos/fuentes/MuseoSansCondensed-900.ttf);
}
@font-face {
  font-family: MuseoSans-700;
  src: url(./recursos/fuentes/MuseoSans_1.otf);
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- GLOBALES ---------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
* {
  margin: 0;
  font-family: MuseoSansCondensed-300;
}
#root{
  height: 100%;
}
.App{
  height: 100%;
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- VARIABLES --------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/*
  ESTAS VARIABLES AHORA SE CONTROLAN DESDE EL COMPONENTE DINÁMICO CREADO:
  /src/utilidades/EstilosGlobales.js
  
  :root{
  --padding-lateral:12vw;
  --height-banner-superior:90px;
  --height-banner-menu:42px;
  --height-pie-pagina:50px;
  --height-contenedor:calc(calc(calc(100vh - var(--height-banner-superior)) - var(--height-banner-menu)) - var(--height-pie-pagina));

  --height-modal-solicitud-header:40px;
  --height-modal-solicitud-footer:50px;
  --height-modal-solicitud-body:calc(calc(92vh - var(--height-modal-solicitud-header)) - var(--height-modal-solicitud-footer));
}*/

/* --- Medidas Maximas --- */
@media only screen and (max-width: 1024px) {
  :root {
    --padding-lateral: 1vw !important;
    --height-banner-superior:90px !important;
  }
}
@media only screen and (max-width: 768px) {
  :root {
    --height-banner-superior:80px !important;
  }
}
/* --- Medidas Minimas --- */
@media only screen and (min-width: 1800px) {
  :root {
    --padding-lateral: 20vw !important;
  }
}
@media only screen and (min-width: 1700px) {
  :root {
    --padding-lateral: 15vw !important;
  }
}
@media only screen and (min-height: 800px) {
  :root {
    /*--height-banner-superior:130px !important;*/
    /*--height-pie-pagina:75px !important;*/
  }
}



/*320px 400px 600px 640px 768px 900px 1024px 1200px*/

/* __________________________________________________________________________________________*/


.graficas-antd {
  display: grid;
  flex-wrap: wrap;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 520px);
  row-gap: 40px;
  margin-bottom: auto;
  margin-top: auto;
}

.graficas-antd .widget-grafica{
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgb(217 217 217);
  background: rgb(255,255,255);
  text-align: center;
}
.graficas-antd .widget-grafica .div-grafica{
  width: 500px;
  height: 340px;
  display: flex;
}
.graficas-antd .widget-grafica .div-grafica .ant-progress{
  margin: auto;
}
.graficas-antd .widget-grafica > label{
  font-size: 20px;
  font-weight: bold;
  color: #6a6a6a;
}

@media only screen and (max-width: 500px) {
  .graficas-antd {
    grid-template-columns: repeat(auto-fill, 375px);
    row-gap: 40px;
  }
  .graficas-antd .widget-grafica .div-grafica{
    width: 355px;
    height: 200px;
  }
}
/*
width: 355px;
  height: 200px;
*/





/* ----------------------------------------------------------------------------------------- */
/* --- VISTA CARGANDO PAGINA --------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.vista-cargando-pagina{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.vista-cargando-pagina img{
  width: 150px;
  margin-top: auto;
  margin-bottom: 5px;
}
.vista-cargando-pagina div.ant-progress{
  margin-top: 5px;
  margin-bottom: auto;
}
/* __________________________________________________________________________________________*/



/* ----------------------------------------------------------------------------------------- */
/* --- ENCABEZADO -------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
header > .banner-superior{
  display: flex;
  height: var(--height-banner-superior);
  background-color: #C90A00;
  background-image: url('./recursos/imagenes/header_fondo.webp');
}
header > .banner-superior > *{
  height: fit-content;
  margin: auto;
}
/*Con este se puede jugar para el responsive y el tamano de las imagenes*/
header > .banner-superior .imagenes-suma .img-banner{
  height: 60px;
}
header > .banner-superior .imagenes-suma .img-banner.logo-suma-eslogan{
  height: 50px;
  margin: auto auto 0 0;
}
header > .banner-superior .imagenes-dadep .img-banner{
  height: 35px;
}
header > .banner-superior > *:first-child{
  margin-left: 0;
}
header > .banner-superior > *:last-child{
  margin-right: 0;
}
header > .banner-superior > .seccion-imagenes{
  display: flex;
}
header > .banner-superior > .seccion-imagenes > *:not(:last-child){
  margin-right: 20px;
}
header > .banner-menu{
  height: var(--height-banner-menu);
  display: flex;
  width: 100%;
}
header > .banner-menu > .ant-menu-horizontal{
  line-height: 35px;
  height: 35px;
}

/* --- Medidas Maximas --- */
@media only screen and (max-width: 900px) {
  .logo-alcaldia-mayor-bogota,
  .separador-vertical.separador-alcaldia-mayor-bogota {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  header > .banner-superior .imagenes-suma .img-banner{
    height: 45px;
  }
  header > .banner-superior .imagenes-suma .img-banner.logo-suma-eslogan{
    height: 40px;
  }
  header > .banner-superior .imagenes-dadep .img-banner{
    height: 35px;
  }
}
@media only screen and (max-width: 690px) {
  .logo-dadep-eslogan,
  .separador-vertical.separador-dadep-eslogan {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .logo-bogota {
    display: none;
  }
}
/* --- Medidas Minimas --- */
/*@media only screen and (min-height: 800px) {
  header > .banner-superior .imagenes-suma .img-banner{
    height: 65px;
  }
  header > .banner-superior .imagenes-suma .img-banner.logo-suma-eslogan{
    height: 60px;
  }
  header > .banner-superior .imagenes-dadep .img-banner{
    height: 50px;
  }
}*/
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- PIE DE PAGINA ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
footer{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--height-pie-pagina);
  background-color: #9D9E9E;
}
footer > *{
  height: fit-content;
  margin: auto;
}
footer a,
footer span{
  color: black;
  font-size: 13px;
}
footer .redes-sociales{
  display: flex;
  width: fit-content;
}
footer .redes-sociales .red-social:not(:last-child){
  margin-right: 10px;
}
footer .footer-superior,
footer .footer-inferior{
  display: flex;
  width: 100%;
}
footer .footer-superior{
  background-color: #676767;
  height: 80px;
}
footer .footer-inferior{
  background-color: #989898;
  height: 20px;
}
footer .footer-inferior > *{
  margin: auto;
}
footer .footer-inferior > *:first-child{
  margin-left: 0;
}
footer .footer-inferior > *:last-child{
  margin-right: 0;
}
footer .seccion-footer{
  margin-top: auto;
  margin-bottom: auto;
}
footer .seccion-footer.redes-sociales{
  margin-right: 0;
  margin-left: auto;
}
footer .seccion-footer.contacto{
  margin-right: 20px;
}
footer .img-footer{
  width: 40px;
  margin: 0 0 auto 0;
}
footer .separador-vertical{
  width: 2px;
  background-color: white;
  height: 40px;
  margin: 5px 8px auto 8px;
}
footer .seccion-footer{
  display: flex;
}
footer table {
  color: white;
}
footer table td {
  line-height: 13px;
  height: 13px;
  font-size: 13px;
}
footer table tr td:nth-child(2){
  padding-left: 10px;
}
@media only screen and (max-width: 768px) {
  :root {
    --height-pie-pagina:260px !important;
  }
  footer .footer-inferior{
    height: 60px;
  }
  footer .footer-superior{
    height: 200px;
  }
  footer .footer-superior, 
  footer .footer-inferior{
    flex-direction: column;
  }
  footer .footer-superior > * ,
  footer .footer-inferior > * {
    margin: auto !important;
  }
  footer table{
    width: 252px;
  }
  /*footer {
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
  }*/

}
/* __________________________________________________________________________________________*/

.comunicaciones .pregunta {
  display: flex;
}
.comunicaciones .pregunta:not(.normatividad) {
  margin-bottom: 10px;
}
.comunicaciones .pregunta > div{
  display: inline-block;
  width: calc(100% - 80px);
  text-align: justify;
}
.comunicaciones .pregunta > div > span,
.comunicaciones .enlace > div > span,
.comunicaciones .respuesta > div > span{
  font-weight: bold;
}
.comunicaciones .pregunta > textarea{
  font-weight: bold;
  display: inline-block;
  width: calc(100% - 80px);
  margin-bottom: 10px;
}
.comunicaciones .pregunta > button{
  margin: 0 0 auto auto;
}
.comunicaciones .pregunta > button.btn-rigth{
  margin: 0 0 auto 0;
}
.comunicaciones .respuesta{
  margin-bottom: 25px;
  border-bottom: 1px solid #d6d6d6;
}
.comunicaciones .respuesta > div{
  display: block;
  text-align: justify;
  margin-bottom: 25px;
}
.comunicaciones .respuesta > textarea{
  width: 100%;
  display: block;
  margin-bottom: 40px;
}
.comunicaciones .enlace > div a {
  padding: 0;
}
.comunicaciones .enlace textarea {
  color: #C90A00;
}
.comunicaciones .texto-entrada label{
  font-weight: bold;
}
.comunicaciones .texto-entrada.titulo textarea{
  height: 80px;
}
.comunicaciones .texto-titulo{
  height: 100px;
  overflow-y: scroll;
  padding-right: 10px;
}
.comunicaciones.que-es-suma .texto-titulo{
  height: 50px;
}
.comunicaciones.que-es-suma .texto-titulo.descripcion{
  height: 120px;
}
.comunicaciones.que-es-suma .texto-entrada.titulo textarea{
  height: 50px;
}
.comunicaciones.que-es-suma .texto-entrada.titulo.descripcion textarea{
  height: 90px;
}

.slider-carrusel{
  display: flex;
  margin-bottom: 40px;
}
.slider-carrusel .seccion-imagen .imagen{
  width: 300px;
  height: 300px;
  background-color: #cccccc;

  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}
.slider-carrusel .pregunta.titulo-imagen > div{
  width: 100%;
  white-space: pre-wrap;
}
.slider-carrusel .seccion-text{
  width: 100%;
  padding-left: 20px;
}
.slider-carrusel .seccion-text .botones-edicion{
  text-align: right;
  margin-bottom: 20px;
}
.slider-carrusel .seccion-text .botones-edicion .upload{
  float: left;
}
.slider-carrusel .seccion-text .enlace{
  margin-top: 15px;
}
.slider-carrusel .seccion-text .enlace button{
  padding-left: 0;
}
.slider-carrusel .seccion-text .div-color{
  width: 80px;
}
.slider-carrusel .seccion-text .opciones{
  margin-top: 20px;
}
.slider-carrusel.que-es-suma .seccion-text .opciones{
  margin-bottom: 20px;
  margin-top: 10px;
}
.slider-carrusel .seccion-text .opciones .ant-select{
  width: 75px;
}

.slider-carrusel .seccion-text .opciones label{
  font-weight: bold;
}
@media only screen and (max-width: 760px) {
  .slider-carrusel{
    display: block;
  }
  .slider-carrusel .seccion-text{
    padding-left: 0;
  }
  .slider-carrusel .seccion-imagen{
    margin-bottom: 10px;
  }
  .slider-carrusel .seccion-imagen .imagen{
    margin: auto;
    width: 220px;
    height: 220px;
  }
}

/* ----------------------------------------------------------------------------------------- */
/* --- MENU -------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.ant-menu.menu-suma{
  /*display: flex;*/
}
.ant-menu.menu-suma.menu-cuenta-usuario{
  margin: 0 0 auto auto;
}

.ant-menu.menu-suma.menu-opciones{
  display: inline-block;
  width: inherit;
}


.menu-suma .ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-usuario{
  margin-right: 20px;
  margin-left: auto;
}
.menu-suma .ant-menu-salir{
  margin-right: 0;
  margin-left: 0;
}
.ant-menu-item.ant-menu-item-selected {
  font-weight: bold;
  color: black !important;
}
.ant-menu-vertical > .ant-menu-item.menu-item-zona-usuarios,
.ant-menu-horizontal > .ant-menu-item.menu-item-zona-usuarios{
  display: none;
}
@media only screen and (max-width: 768px) {
  .ant-menu-vertical > .ant-menu-item.menu-item-zona-usuarios,
  .ant-menu-horizontal > .ant-menu-item.menu-item-zona-usuarios{
    display: inline-block;
  }
}
@media only screen and (max-width: 1007px) {
  .ant-menu.menu-suma.menu-cuenta-usuario span.titulo-usuario,
  .ant-menu.menu-suma.menu-cuenta-usuario span.anticon.anticon-user.icono-suma{
    display: none;
  }
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- CONTENEDOR -------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.contenedor{
  background-color: #e8e8e8;
}

@media only screen and (max-width: 640px) {
  .contenedor{
    min-height: var(--height-contenedor);
    height: fit-content;
  } 
}
/* __________________________________________________________________________________________*/

/* ----------------------------------------------------------------------------------------- */
/* --- PRINCIPAL --------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.principal{
  display: flex;
}
.principal .ant-carousel{
  height: 100%;
  min-height: 400px;
}
/*.principal .ant-carousel,*/
.principal .contenido-principal {
  width: 100%;
}

.principal .principal-home {
  padding-top: 10px;
}

.principal .seccion-principal{
  width: 100%;
  min-height: var(--height-contenedor);
}

.principal .seccion-principal .padding-principal{
  padding: 20px 4vw;
}
.principal .seccion-principal .padding-principal.contenido-solicitudes{
  padding: 20px 10px;
}
.principal .seccion-principal .padding-principal.contenido-usuarios{
  padding: 20px 2vw;
}

.principal .seccion-principal .titulo-seccion > label{
  color: #C90A00;
  font-weight: bold;
  vertical-align: middle;
}
.principal .seccion-principal .sub-titulo-seccion > label{
  color: #757575;
  font-weight: bold;
  vertical-align: middle;
}
.principal .seccion-principal .titulo-seccion > label > span.anticon{
  vertical-align: text-bottom;
  margin-right: 10px;
}
.principal .seccion-principal .titulo-seccion > label > span.anticon > svg {
    width: 20px;
    height: 20px;
    padding: 2px;
}
.principal .seccion-principal .titulo-seccion > label > span.anticon.icon-background-yellow > svg{
    background: #f7c034;
    color: white;
}
.principal .seccion-principal .titulo-seccion > label > span.anticon.icon-background-red > svg{
    background: #C90A00;
    color: white;
}
.principal .seccion-principal .titulo-seccion > p {
  color: black;
  margin-top: 10px;
}

.principal .principal-transition-group{
  width: 60%;
}

.sub-seccion{
  margin-bottom: 20px;
}

.principal .principal-transition-group .contenido-principal{
  background-color: white;
  padding: 30px 50px;
  overflow-y: scroll;
  height: 100%;
}
.principal .principal-transition-group .contenido-principal.seccion-con-zona-usuario{
  overflow-y: hidden;
  padding: 0px;
}
.principal .contenido-principal .seccion-titulo{
  margin-bottom: 20px;
}
.principal .contenido-principal .titulo-contenido > span{
  color: #C90A00;
  font-size: 28px;
  font-family: MuseoSansCondensed-700;
  position: relative;
  animation: movimientoTituloContenido 1s ease-out;  
}
@keyframes movimientoTituloContenido
{ 
from { left: 40px; opacity: 0;}
to { left: 0px; opacity: 1;}
}

.principal .contenido-principal .titulo-contenido > .separador-horizontal{
  background-color: #C90A00;
}
.principal .contenido-principal .seccion-imagen-texto{
  /*display: flex;*/
}
.principal .contenido-principal .seccion-imagen-texto .div-imagen{
  height: 180px;
  width: 40%;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover; 

  float: left;
  margin-right: 20px;
}
.principal .contenido-principal .seccion-imagen-texto .texto{
  /*width: 60%;
  padding-left: 20px;*/
  text-align: justify;
  white-space: pre-wrap;
}
.principal .contenido-principal .seccion-texto{
  padding-top: 20px;
}

.principal .zona-usuario-estatico{
  width: 38%;
  margin-left: auto;
  margin-right: 0;
  background-color: white;;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
}
.principal .zona-usuario-diseno .titulo{
  color: #C90A00;
  font-family: MuseoSansCondensed-500;
}
.principal .zona-usuario-diseno .sub-titulo{
  display: block;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.principal .zona-usuario-diseno .separador-horizontal{
  background-color: #9D9E9E;
  height: 1px;
}
.principal .zona-usuario-diseno .btn-crear-cuenta > span,
.principal .zona-usuario-diseno .btn-recuperar-contrasena > span {
  font-family: MuseoSansCondensed-700;
}
.principal .zona-usuario-diseno .btn-recuperar-contrasena {
  padding: 0;
  height: auto;
}
.principal .zona-usuario-diseno .btn-recuperar-contrasena.activar-cuenta{
  color: #c90a00;
}
@media only screen and (max-width: 900px) {
  /*.principal .contenido-principal,*/
  .principal .principal-transition-group .contenido-principal{
    padding: 3vh 3vw;
  }
  .principal .contenido-principal .seccion-imagen-texto .div-imagen,
  .principal .contenido-principal .seccion-imagen-texto .texto{
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .principal .zona-usuario-estatico{
    padding-left: 10px;
    padding-right: 10px;
  }
  .principal .zona-usuario .zona-usuario-crear-cuenta{
    text-align: center;
  }
  .principal .contenido-principal .seccion-imagen-texto{
    flex-direction: column;
  }
  .principal .contenido-principal .seccion-imagen-texto .div-imagen,
  .principal .contenido-principal .seccion-imagen-texto .texto {
    width: 100%;
  }
  .principal .contenido-principal .seccion-imagen-texto .texto{
    padding: 0;
  }
  .principal .seccion-principal{
    height: auto;
  }
  /*.principal .ant-carousel,*/
  .principal .principal-transition-group,
  .principal .contenido-principal {
    width: 100%;
  }
  .principal .zona-usuario-estatico{
    display: none;
  }
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- ZONA USUARIO ------------------------------------------------------------------------ */
/* ----------------------------------------------------------------------------------------- */

.zona-usuario-logeado .seccion-superior-foto-datos{
  display: flex;
  padding: 30px 0;
}
.zona-usuario-logeado.usuario-dependencia .seccion-superior-foto-datos{
  padding: 15px 0;
}

.zona-usuario-logeado .seccion-superior-foto{
  margin-right: 20px;
}
.zona-usuario-logeado .nombre-usuario{
  font-family: MuseoSansCondensed-700;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;
}

.foto-usuario{
  width: 120px;
  height: 120px;
  background-color: #C90A00;
  border-radius: 50%;
  border: 2px solid #C90A00;
  background-image: url('./recursos/imagenes/foto_usuario.png');
}
.subir-foto div.ant-upload-list-item{
  height: fit-content;
}
.subir-foto span.ant-upload-list-item-name{
  white-space: pre-wrap;
}
/* --- SELECCION TIPO USUARIO */
.seleccion-tipo-usuario{
  display: flex;
  margin-top: 12vh;
}
.seleccion-tipo-usuario > *:first-child{
  margin-left: auto;
  margin-right: 2.5vw;
}
.seleccion-tipo-usuario > *:last-child{
  margin-left: 2.5vw;
  margin-right: auto;
}
.seleccion-tipo-usuario .opcion-tipo-usuario{
    display: flex;
    flex-direction: column;
    width: fit-content;
}
.seleccion-tipo-usuario .opcion-tipo-usuario > label{
    display: inline-block;
    margin: auto;
    margin-top: 5px;
    font-weight: bold;
}
.seleccion-tipo-usuario .opcion-tipo-usuario > button {
    width: 150px;
    height: 150px;
    border-radius: 10px;
}
.seleccion-tipo-usuario .opcion-tipo-usuario > button > span {
    width: 100%;
    height: 100%;
    display: flex;
}
.seleccion-tipo-usuario .opcion-tipo-usuario > button > span > svg {
    width: 80%;
    height: 80%;
    margin: auto;
}
.seleccion-tipo-usuario .opcion-tipo-usuario.persona-juridica > button{
    background: #f7c034;
    border-color: #f7c034;
}
.seleccion-tipo-usuario .opcion-tipo-usuario.persona-juridica > button:hover{
    background: #f8c84e;
    border-color: #f8c84e;
}
.seleccion-tipo-usuario .opcion-tipo-usuario.persona-juridica > button:active{
  background: #e0ac29;
  border-color: #e0ac29;
}
.seccion-media-datos.datos-basicos .info-datos{
  margin-bottom: 15px;
}
.seccion-media-datos.datos-basicos .info-datos > label{
  display: block;
  font-weight: bold;
}
.seccion-media-datos.datos-basicos .info-datos > span{
  display: block;
  padding-left: 5px;
  border-top: 1px solid #d7d7d7;
}
.zona-usuario-logeado.usuario-dependencia .seccion-media-datos.datos-basicos .info-datos{
  margin-bottom: 2px;
}

@media only screen and (max-width: 400px) {
  .seleccion-tipo-usuario .opcion-tipo-usuario > button {
    width: 130px;
    height: 130px;
    border-radius: 10px;
  }
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- MIS SITIOS (OPCIONES) --------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.mis-sitios .ant-spin-nested-loading{
  height: 100%;
}
.mis-sitios .ant-spin-nested-loading > .ant-spin-container{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mis-sitios .titulo-seccion{
  margin-bottom: 10px;
}
.seleccion-mis-sitios{
  display: grid;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 140px);
  row-gap: 20px;
  margin-bottom: auto;
  margin-top: auto;
}
.seleccion-mis-sitios > *{
  margin: auto;
}

.seleccion-mis-sitios .opcion-mi-sitio{
    display: flex;
    flex-direction: column;
    width: fit-content;
}
.seleccion-mis-sitios .opcion-mi-sitio > label{
    display: inline-block;
    margin: auto;
    margin-top: 5px;
    font-weight: bold;
    color: #c90a00;
}
.seleccion-mis-sitios .opcion-mi-sitio.disabled > label{
    color: #9d9e9e;
}
.seleccion-mis-sitios .opcion-mi-sitio > button {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    background: #e8e8e8;
    border-color: #e8e8e8;
    color: #c90a00;
}
.seleccion-mis-sitios .opcion-mi-sitio > button:hover,
.seleccion-mis-sitios .opcion-mi-sitio > button:focus,
.seleccion-mis-sitios .opcion-mi-sitio > button:active{
    background: #c90a00;
    border-color: #c90a00;
    color:  #e8e8e8;
}
.seleccion-mis-sitios .opcion-mi-sitio > button[disabled],
.seleccion-mis-sitios .opcion-mi-sitio > button[disabled]:hover,
.seleccion-mis-sitios .opcion-mi-sitio > button[disabled]:focus,
.seleccion-mis-sitios .opcion-mi-sitio > button[disabled]:active{
  background: #d0d0d0;
  border-color: #d0d0d0;
  color: #e8e8e8;
}
.seleccion-mis-sitios .opcion-mi-sitio > button > span {
    width: 100%;
    height: 100%;
    display: flex;
}
.seleccion-mis-sitios .opcion-mi-sitio > button > span > svg {
    width: 80%;
    height: 80%;
    margin: auto;
}
@media only screen and (max-width: 1120px) {
  .seleccion-mis-sitios{
    justify-content: center;
  }
}
@media only screen and (max-width: 1224px) {
  .seleccion-mis-sitios .opcion-mi-sitio > button {
    width: 125px;
    height: 125px;
  }
  .seleccion-mis-sitios .opcion-mi-sitio > label{
    font-size: 12px;
  }
}
@media only screen and (min-width: 1280px) {
  .seleccion-mis-sitios{
    grid-template-columns: repeat(3, 140px);
  }
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- FORMULARIOS ------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.formulario-suma .ant-form-item-label{
  text-align: left;
}
.wrap-label-formulario .ant-form-item-label > label{
  white-space: normal;
  height: 33px;
}
/* __________________________________________________________________________________________*/

.seccion-datos .titulo-separador{
  margin-top: 12px;
  margin-bottom: 5px;
  display: block;
  border-bottom: 1px solid #dadada;
}
.seccion-datos .titulo-etiqueta{
  font-weight: bold;
}
.seccion-datos label,
.seccion-datos span{
  word-break: break-word;
}
.seccion-datos .fila{
  margin-bottom: 5px;
}
.seccion-datos .fila.documento{
  margin-bottom: 30px;
}
.seccion-final{
  margin-bottom: 50px;
}

/* ----------------------------------------------------------------------------------------- */
/* --- ETIQUETAS - TAGS -------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.seccion-filtros-etiquetas{
  margin-top: 8px;
  margin-bottom: 8px;
}
.seccion-filtros-etiquetas > div.titulo-filtro-etiquetas{
  display: inline-block;
  margin-right: 10px;
}
.seccion-filtros-etiquetas > div.titulo-filtro-etiquetas > label{
  font-weight: bold;
}

.etiqueta-filtro{
  display: inline-block;
  background-color: #c3e2ff;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  margin-right: 5px;
  margin-bottom: 2px;
}
/* _________________________________________________________________________________________ */

/* ----------------------------------------------------------------------------------------- */
/* --- CARRUSEL ---------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.carrusel .pagina-carrusel{
  height: 100%;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 40px;

  background-repeat: no-repeat;
  background-position: center;
  background-size:cover; 

  display: flex;
  flex-direction: column;
}

.carrusel .contenedor-spin{
  width: 100%;
  height: 100%;
  display: flex;
}
.carrusel .contenedor-spin .ant-spin{
  margin: auto;
}

.carrusel .pagina-carrusel > *:not(img){
  position: relative;
  z-index: 1;
}

.carrusel .pagina-carrusel > span{
  color: white;
  font-size: 26px;
  font-family: MuseoSans-700;
  position: relative;
  animation: movimientoTitulo 1s ease-out;
  white-space: pre-wrap;
  margin: auto auto 0 0;
}
@keyframes movimientoTitulo
{ 
  from { top: -40px; opacity: 0;}
  to { top: 0px; opacity: 1;}
}

.carrusel .pagina-carrusel > button{
  width: fit-content;
  position: relative;
  animation: movimientoButton 1.5s ease-out;
}
@keyframes movimientoButton
{ 
  from { top: 80px; opacity: 0;}
  to { top: 0px; opacity: 1;}
}

.carrusel .pagina-carrusel > .separador-horizontal{
  margin: 10px 0 auto 0;
  width: 100px;
  height: 4px;
  position: relative;
  animation: movimientoSeparador 1.5s ease-out;
}
@keyframes movimientoSeparador
{ 
  from { left: 100px; opacity: 0;}
  to { left: 0px; opacity: 1;}
}

.ant-carousel,
.ant-carousel > div.carrusel,
.ant-carousel > div.carrusel > div.slick-list,
.ant-carousel > div.carrusel > div.slick-list > div.slick-track{
  height: 100%;
}

.ant-carousel .slick-slide > div {
  background: #dcdcdc;
  height: 100%;
}
.ant-carousel .slick-slide > div > div {
  height: 100%;
}
.ant-carousel .slick-dots li{
  width: 48px !important;
}
.ant-carousel .slick-dots li.slick-active{
  width: 52px !important;
}
.carrusel .pagina-carrusel .logos{
  display: flex;
  /*background-color: rgba(255, 0, 0, 0.5);*/
}
.carrusel .pagina-carrusel .logos .logo_suma_circular{
  width: 140px;
  height: 140px;
  margin-top: 30px;
}
.carrusel .pagina-carrusel .logos .logo_un_lugar_como_el_hogar{
  width: 90px;
  height: 48px;
  margin: 0 0 auto auto;
}
@media only screen and (max-width: 400px) {
  .carrusel .pagina-carrusel > span{
    font-size: 24px;
  }
}
/* --- Medidas Minimas --- */
@media only screen and (min-width: 1400px) {
  .carrusel .pagina-carrusel > span{
    font-size: 3em;
    line-height: 1.5em;
  }
}
@media only screen and (max-width: 768px) {
  .carrusel .pagina-carrusel{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- SLIDERS ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.slider01{
  background-image: url('./recursos/imagenes/sliders/slider_1.jpg');
}
.slider02{
  background-image: url('./recursos/imagenes/sliders/slider_2.jpg');
}
.slider03{
  background-image: url('./recursos/imagenes/sliders/slider_3.jpg');
}
.slider04{
  background-image: url('./recursos/imagenes/sliders/slider_4.jpg');
}
/* __________________________________________________________________________________________*/

/* ----------------------------------------------------------------------------------------- */
/* --- ENLACES -=--------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.div-lista-botones{
  text-align: right;
  width: 100%;
  margin-bottom: 10px;
}
.enlace-documento{
  display: inline-block;
}
.enlace-documento > div{
  display: flex;
}
.enlace-documento > div > span > svg{
  vertical-align: middle;
}
.enlace-documento.tamano-25 > div > span{
  font-size: 25px;
  margin-right: 5px;
}
.enlace-documento.tamano-25 > div > label{
  line-height: 25px;
}
/* __________________________________________________________________________________________*/

/* ----------------------------------------------------------------------------------------- */
/* --- ENLACES -=--------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.enlace-circular {
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  position: relative;
  display: flex;
}
.enlace-circular:hover{
  color: #C90A00;
}
.enlace-circular > *{
  display: flex;
}
.enlace-circular a{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  color: transparent !important;
}
.enlace-circular .img-enlace{
  width: 35px;
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- BOTONES  ---------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.tipos-de-solicitudes{
  margin-top: 15px;
  display: grid;
  flex-wrap: wrap;
  justify-content:space-between;
  grid-template-columns: repeat(auto-fill, 210px);
}
.tipos-de-solicitudes > *.tipo-solicitud{
  margin: auto;
  margin-bottom: 10px;
}
.tipos-de-solicitudes .tipo-solicitud.disabled{
  cursor: not-allowed;
}
.tipos-de-solicitudes .tipo-solicitud > button,
.tipos-de-solicitudes .tipo-solicitud > span > button{
  display: flex;
  padding: 5px 10px;
  width: 210px;
  border: none;
  border-radius: 10px;
  background: #e8e8e8;
}
.tipos-de-solicitudes .tipo-solicitud.disabled > button,
.tipos-de-solicitudes .tipo-solicitud.disabled > span > button{
  background: #f9f9f9;
  cursor: not-allowed;
}
.tipos-de-solicitudes .tipo-solicitud > button:focus{
  outline: 2px solid #C90A00;
  outline-style: auto;
}
.tipos-de-solicitudes .tipo-solicitud:not(.disabled) > button:hover{
  box-shadow: 0px 0px 5px 1px rgb(197 197 197);
}

.tipos-de-solicitudes .tipo-solicitud > button img,
.tipos-de-solicitudes .tipo-solicitud > span > button img{
  margin-left: 0;
  margin-right: 0;
}
.tipos-de-solicitudes .tipo-solicitud > button label,
.tipos-de-solicitudes .tipo-solicitud > span > button label{
  font-family: MuseoSansCondensed-500;
  font-size: 12px;
  line-height: 12px;
  width: 105px;
}
.tipos-de-solicitudes .tipo-solicitud.disabled > button label,
.tipos-de-solicitudes .tipo-solicitud.disabled > span > button label{
  color: #b3b3b3;
}
.tipos-de-solicitudes .tipo-solicitud > button .separador-vertical,
.tipos-de-solicitudes .tipo-solicitud > span > button .separador-vertical{
  display: inline-block;
  width: 2px;
  height: 60px;
  background: #e80b2c;
  margin-left: 10px;
  margin-right: 10px;
}
.tipos-de-solicitudes .tipo-solicitud.disabled > button .separador-vertical,
.tipos-de-solicitudes .tipo-solicitud.disabled > span > button .separador-vertical{
  background: #d9d9d9;
}
.tipos-de-solicitudes .tipo-solicitud > button > *,
.tipos-de-solicitudes .tipo-solicitud > span > button > * {
  margin: auto;
}
/* __________________________________________________________________________________________*/





/* ----------------------------------------------------------------------------------------- */
/* --- AUTOCOMPLETE - ANT DESIGN - ANTD  --------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.ant-select-auto-complete input[type=search].ant-input,
.ant-select-auto-complete button[type=button].ant-input-search-button{
  height: 32px;
}
.ant-select-auto-complete button[type=button].ant-input-search-button{
  padding: 0 10px;
}
.row-error-autocomplete label,
.row-error-autocomplete input[type=search]{
  color: red;
  border-color: red;
}
/* __________________________________________________________________________________________*/



/* ----------------------------------------------------------------------------------------- */
/* --- REACT COLOR ------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.div-color{
  width: 100%;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  border: 6px solid rgb(212, 212, 212);
}
.div-selector-color{
  position: absolute;
  z-index: 2;
}
.div-selector-color-cover{
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- SELECTOR ICONO  --------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.ant-modal.modal-selector-icono div.ant-modal-body{
  height: 300px;
  overflow-y: scroll;
}
button.boton-icono-lista{
  width: 50px;
  height: 50px;
}
button.boton-icono-lista,
button.boton-icono-lista > span{
  font-size: 30px;
}
button.icono-seleccionado{
  font-size: 18px;
}
button.icono-seleccionado > span{
  font-size: 20px;
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- TEXTAREA - ARMADOR DE ETIQUETAS ----------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
div.text-area-etiquetas-entrada{
  margin-bottom: 0;
}
div.text-area-etiquetas{
  margin-bottom: 30px;
}
div.text-area-etiquetas > button.ant-btn{
  background: #ececec;
}
/* __________________________________________________________________________________________*/



/* ----------------------------------------------------------------------------------------- */
/* --- TRANSFER ANT DESIGN ----------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.transfer-lista > .ant-transfer-list{
  width: 50%;
  height: 300px;
}

.item-transfer-rol > span{
  display: block;
  line-height: 16px;
}
.item-transfer-rol > span:nth-child(1){
  color: black;
  font-weight: bold;
}
.item-transfer-rol > span:nth-child(2){
  color: #adadad;
}
/* __________________________________________________________________________________________*/



/* ----------------------------------------------------------------------------------------- */
/* --- BOTONES  ---------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.boton-cancelar-actualizar{
  display: block !important;
  padding: 0 !important;
  height: 22px !important;
}
.boton-opcion{
  margin-right: 10px;
  margin-bottom: 5px;
}
.boton-opcion-list{
  margin: 1px !important;
}
.boton-agregar-actualizar-doc{
  width: 104px;
}
button.ant-btn.ant-btn-circle.ant-btn-icon-only:disabled > span{
  color: #9e9e9e !important;
}
button.ant-btn.boton-opcion:disabled > span,
button.ant-btn:disabled > span{
  color: #9e9e9e !important;
}
@media only screen and (max-width: 576px) {
  .boton-opcion-list.btn-dependencia{
    display: none;
  }
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- ANT DESIGN CHECKBOX  ---------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.ant-checkbox-group.checkbox-vertical > .ant-checkbox-group-item{
  display: block;
}
.ant-checkbox-group.checkbox-vertical > .ant-checkbox-group-item span.ant-checkbox-inner{
  display: inline-block;
}
/* __________________________________________________________________________________________*/

/* ----------------------------------------------------------------------------------------- */
/* --- ANT DESIGN TABLE  ------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.table-suma .ant-table-thead > tr > th{
  background: #e8e8e8;
}
.table-suma .ant-table-thead > tr > th,
.table-suma .ant-table-tbody > tr > td, 
.table-suma .ant-table tfoot > tr > th,
.table-suma .ant-table tfoot > tr > td{
  padding: 6px 8px;
} 

.ocultar-header .ant-table-thead{
  display: none;
}

.td-font-bold .ant-table-tbody > tr:not(.ant-table-expanded-row) > td{
  font-weight: bold;
}

td.columna-opciones > button{
  margin-right: 5px;
}


.tabla-comprimida .ant-table .ant-table-thead > tr > th {
  padding: 2px 6px;
}
.tabla-comprimida .ant-table .ant-table-tbody > tr > td{
  padding: 4px 6px;
}

.ant-table-column-sorters{
  padding: 0 !important;
}

.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title{
  padding: 0 1.5em 0 0 !important;
}

/*Esto solo es válido para la columna que contiene el usuario, es decir el alias */
@media only screen and (max-width: 340px) {
  .td-usuario-alias > span{
    word-wrap: break-word;
    display: block;
    max-width: 160px;
  }
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- TABLA SOLICITUDES  ------------------------------------------------------------------ */
/* ----------------------------------------------------------------------------------------- */
.tabla-solicitudes .texto-info01{
    color: #b3b3b3;
    font-size: 13px;
}
.tabla-solicitudes .texto-info02{
  color: #636363;
  font-weight: bold;
}
.tabla-solicitudes .fecha-opcional{
  color: #f7c034;
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- VER SOLICITUD  ---------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.encabezado-ver-solicitud{
  display: flex;
  margin-bottom: 10px;
}
.encabezado-ver-solicitud > img{
  margin-right: 24px;
}
.encabezado-ver-solicitud .texto-encabezado > label{
  display: block;
  line-height: 20px;
}
.seccion-datos .titulo-etiqueta.titulo-ver-solicitud{
  color: #c90a00;
  font-weight: lighter;
}

/* __________________________________________________________________________________________*/



/* ----------------------------------------------------------------------------------------- */
/* --- ANT DESIGN SPIN  ------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.cargando-pagina{
  display: flex;
  height: 100%;
}
.cargando-pagina .ant-spin-spinning{
  margin: auto;
}
.ant-spin.solo-spin-cargando{
  width: 100%;
  padding-top: 90px;
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- ANT DESIGN POPOVER ------------------------------------------------------------------ */
/* ----------------------------------------------------------------------------------------- */
.ant-popover .ant-popover-content .ant-popover-title{
  color: #C90A00;
  font-weight: bold;
}

.popover-tipo-solicitud{
  max-width: 320px;
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- ANT DESIGN DIVIDER ------------------------------------------------------------------ */
/* ----------------------------------------------------------------------------------------- */
div.ant-divider{
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* __________________________________________________________________________________________*/



/* ----------------------------------------------------------------------------------------- */
/* --- ANT DESIGN MODAL  ------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.modal-suma .ant-modal-header{
  padding: 8px 16px;
}
.modal-suma .ant-modal-title{
  color: #c90a00;
}
.modal-suma .ant-modal-body{
  padding: 8px 16px;
}
.modal-suma p{
  margin-bottom: 0;
}
.modal-suma .ant-modal-confirm-content{
  white-space: pre-line;
}
.modal-suma.icono-con-texto .ant-modal-body{
  display: flex;
}
.modal-suma.icono-con-texto .ant-modal-body p{
  margin-left: 15px;
}
.modal-suma.icono-con-texto .ant-modal-body svg{
  width: 50px;
  height: 50px;
  color: #c90a00;
  background: #e4e4e4;
  padding: 5px;
}

.modal-solicitud.ant-modal{
  min-width: 750px;
  padding-bottom: 24px;
}
.modal-solicitud.ant-modal .ant-modal-content{
  height: 100%;
}
.modal-solicitud.ant-modal .ant-modal-content > button.ant-modal-close > .ant-modal-close-x{
    width: var(--height-modal-solicitud-header);
    height: var(--height-modal-solicitud-header);
    line-height: var(--height-modal-solicitud-header);
}
.modal-solicitud.ant-modal .ant-modal-header{
  height: var(--height-modal-solicitud-header);
  padding: 8px 24px;
}
.modal-solicitud.ant-modal .ant-modal-header .ant-modal-title{
  color: #c90a00;
  font-family: MuseoSansCondensed-500;
}
.modal-solicitud.ant-modal .ant-modal-footer{
  height: var(--height-modal-solicitud-footer);
  padding: 8px 24px;
}
.modal-solicitud.ant-modal .ant-modal-body{
  max-height: var(--height-modal-solicitud-body);
  overflow-y: scroll;
  padding-top: 10px;
}
@media only screen and (max-width: 750px) {
  .modal-solicitud.ant-modal{
    min-width: auto;
  }
}
/* __________________________________________________________________________________________*/




/* ----------------------------------------------------------------------------------------- */
/* --- SELECT - OPTIONS -------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.ant-select-item-option-content .item-personalizado{
  white-space: break-spaces;
}
.item-personalizado .titulo-item{
  line-height: 15px;
  display: inline-block;
}
.item-personalizado .sub-titulo-item{
  color: #b7b7b7;
}
/* __________________________________________________________________________________________*/



/* ----------------------------------------------------------------------------------------- */
/* --- PARAMETROS GENERALES ---------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
input.ant-input.input-parametro-general,
.ant-input-number.input-parametro-general,
span.ant-input-password.input-parametro-general,
.switch-parametro-general,
.ant-picker.input-parametro-general{
  width: 300px;
}
input.ant-input.input-parametro-general.prueba-smtp-correo{
  width: 266px;
}
form.formulario-smtp .ant-col.ant-form-item-label{
  margin-left: auto;
  text-align: left;
}
form.formulario-smtp .ant-col.ant-form-item-control,
form.formulario-smtp .ant-col.ant-form-item-control .ant-form-item-control-input{
  width: 332px;
}
form.formulario-smtp .ant-col.ant-form-item-control{
  width: 332px;
  display: block;
  flex: none;
  margin-right: auto;
}
form.formulario-smtp .ant-input.smtp-correo-electronico{
  width: 266px;
}


.row-parametro-general{
  margin-bottom: 5px;
}
.row-parametro-general > div.ant-col:nth-child(1){
  margin-left: auto;
  margin-right: 0;
}
.row-parametro-general > div.ant-col:nth-child(2){
  margin-left: 0;
  margin-right: auto;
}
@media only screen and (max-width: 708px) {
  .row-parametro-general > div.ant-col:nth-child(1){
    margin-left: 0;
    margin-right: auto;
  }
}
@media only screen and (max-width: 533px) {
  input.ant-input.input-parametro-general,
  span.ant-input-password.input-parametro-general,
  .switch-parametro-general{
    width: 200px;
  }
}
/* __________________________________________________________________________________________*/



/* ----------------------------------------------------------------------------------------- */
/* --- ICONOS ------------------------------------------------------------------------------ */
/* ----------------------------------------------------------------------------------------- */
.anticon.icono-suma {
  color: #C90A00;
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- GENERALES --------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.padding-laterales{
  padding-left: var(--padding-lateral);
  padding-right: var(--padding-lateral);
}
.separador-vertical{
  width: 2px;
  background-color: white;
}
.separador-horizontal{
  height: 2px;
  background-color: white;
}
.separador-horizontal-completo{
  height: 1px;
  width: 100%;
  background: #bbbbbb;
}
.separador-horizontal-completo02{
  height: 1px;
  width: 100%;
  background: #dedede;
}
.div-imagen{
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover; 
}
.flex{
  display: flex;
}
.bkg-white{
  background-color: white;
}
.seccion-flex{
  display: flex;
  flex-direction: column;
}
/* Est grow hace que el elemento complete el alto (Height) del contenedor padre, es decir: lo estira hasta rellenar.*/
.seccion-flex .seccion-flex-grow-1{
  flex-grow: 1;
}
.inline-block{
  display: inline-block;
}
.titulo{
  font-weight: bold;
}
.sub-titulo-separador{
  border-bottom: 1px solid #b9b9b9;
  color: black;
}
.sub-titulo-separador.margen-top{
  margin-top: 20px;
}
.sub-titulo-separador.margen-bottom{
  margin-bottom: 20px;
}
.span-bloque{
  display: block;
}
.lineh16{
  line-height: 16px;
}
.margen-bottom-12{
  margin-bottom: 12px;
}
.margen-bottom-2{
  margin-bottom: 2px;
}
.margen-bottom-10{
  margin-bottom: 10px;
}
.margen-bottom-10-imp{
  margin-bottom: 10px !important;
}
.margen-bottom-5{
  margin-bottom: 5px;
}
.margen-bottom-20{
  margin-bottom: 20px;
}
div.row_col_span_texto > div > span{
  text-align: justify;
  display: block;
}
.texto-gris-claro{
  color: #b3b3b3;
}
.texto-error{
  color: red;
}
.bold{
  font-weight: bold;
}
.fuente-geekblue{
  color: #67b0f6;
}
.fuente-green{
  color: #77c673;
}
.fuente-purple{
  color: #9569de;
}
.fuente-volcano{
  color: #fdb164;
}
.fuente-black{
  color: black;
}
.alinear-centro{
  text-align: center;
}
/* __________________________________________________________________________________________*/


.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.my-node-exit {
  opacity: 0;
  display: none;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
  display: none;
}




/* ----------------------------------------------------------------------------------------- */
/* --- BLOQUEO DE PANTALLA ----------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.bloqueo-pantalla{
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffffd1;
  z-index: 20;
}
.bloqueo-pantalla > .ant-spin{
  margin: auto;
}

#bloqueo-ui{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff99;
  z-index: 1001;
}
#bloqueo-ui > * {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* __________________________________________________________________________________________*/


/* ----------------------------------------------------------------------------------------- */
/* --- REGISTROS --------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
.div-contenedor-registro{
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-break: break-word;
    max-height: 1000px;
    overflow-x: scroll;
}
/* __________________________________________________________________________________________*/